
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { map } from "rxjs/operators";
import { CommonService } from "src/app/shared/common.service";
import { WebapiService } from "src/app/shared/webapi.services";

@Injectable({ providedIn: "root" })
export class InspectionService {
  User_details: any;
  constructor(
    public CF: CommonService,
    private Service: WebapiService
  ) {
    this.User_details=JSON.parse(localStorage.getItem("User_details"));
  }
  resolve(route: ActivatedRouteSnapshot) {
    return;
  }



  Ezee_Reasons_Rejections(): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.Service.serviceUrl}/Ezee_Reasons_Rejections?page=1&itemsPerPage=1000`;
        return this.Service.Http.get<any>(url).pipe(
          map((d: any) => (d.code == "1") ? d.document.records : false)
        ).subscribe((r: any) => resolve(r), reject);
      });
    } catch (error) {
      console.log(error);
    }
  }

  add_Job(data: any): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.Service.serviceUrl}/Ezee_Reasons_Rejections`;
        const body = {
          "sno": 0,
          "client_id": 0,
          "rejection_id": "string",
          "template_id": 0,
          "field_id":0,
          "reason_id": 0,
          "reason": "string",
          "status": 10,
          "remarks": "string",
          "user_id": this.User_details.user_id,
          "transdate": "2021-12-26T03:39:14.653Z",
          "jsondata": "string",
          "emailJSON": "string",
          "aI_status": 0,
          "original_Appl_ID": "2",
          "insp_status": 0,
          "company_Job_ID":data.Jobid?data.Jobid.toString():"",
          "userName":data.fullname.toString().trim(),
          "mobileNo":data.phonenumber.toString(),
          "link": "string",
          "emailID":data.email,
          "job_Id":data.Jobid.toString(),
          "recommendations": false,
          "tC_Date":new Date(),
          "job_creation_Date":new Date(),
          "linkhash": "string"
          }
        return this.Service.Http.post<any>(url, body).pipe(
          map((d: any) => (d.code === 1 ? d : false))
        )
          .subscribe((r: any) => resolve(r), reject);

      });
    } catch (error) {
      console.log(error);
    }
  }

  public async workshp_assign(body: any,) {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.Service.serviceUrl}/SP/Get_Assignedjobs`;
        this.Service.Http.post(url, body).pipe(
          map((d: any) => (d.code === 1 ? d : false))
        ).subscribe((r: any) => resolve(r), reject);
      });
    } catch (error) {
      console.log(error)
    }
  }

  workshop_list(): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.Service.serviceUrl}/Ezee_Users?page=1&itemsPerPage=1000`;
        return this.Service.Http.get<any>(url).pipe(
          map((d: any) => (d.code == "1") ? d.document.records : false)
        ).subscribe((r: any) => resolve(r), reject);
      });
    } catch (error) {
      console.log(error);
    }
  }
}
